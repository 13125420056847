import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import { Icon } from "@atoms";
import { m } from "framer-motion";
import { Player, Youtube, Vimeo, usePlayerContext } from "@vime/react";
import useLang from "@hooks/useLang";
import t from "@utils/t";

const VideoPlayerInline = ({ url, transcript }) => {
  const lang = useLang();

  const [progress, setProgress] = useState(0);
  const ref = useRef();

  let id;
  const provider = url.match("http(s)?://(www.)?youtube|youtu.be")
    ? "youtube"
    : "vimeo";
  if (provider === "youtube") {
    if (url.match("embed")) {
      [id] = url.split(/embed\//)[1].split('"');
    } else {
      [id] = url.split(/v\/|v=|youtu\.be\//)[1].split(/[?&]/);
    }
  }

  if (provider === "vimeo") {
    id = url.split("/").pop();
  }

  const [currentTime, setCurrentTime] = usePlayerContext(ref, "currentTime", 0);
  const [duration] = usePlayerContext(ref, "duration", -1);
  // const [playing] = usePlayerContext(ref, "playing", -1);
  // const [isPlaybackReady] = usePlayerContext(ref, "playbackReady", false);
  const [paused, setPaused] = usePlayerContext(ref, "paused", true);

  useEffect(() => {
    setProgress(currentTime / duration);
  }, [currentTime]);

  return (
    <div
      className={
        classNames()
        // "relative bottom-0 left-0 right-0 z-[950] bg-black p-3 text-white transition duration-200",
      }
    >
      <div className="relative flex w-full items-end gap-3">
        <button
          className="absolute top-0 right-0 z-20 h-20 w-20 -translate-y-1/2 translate-x-1/2 rounded-full border-2 border-current p-3"
          type="button"
          onClick={() => setPaused(!paused)}
        >
          <span className="sr-only">{paused ? "pause" : "play"}</span>
          <Icon
            name="play"
            fitHeight
            className={classNames(
              "absolute inset-0 p-2 transition duration-100",
              {
                "opacity-0": !paused,
              }
            )}
          />
          <Icon
            name="pause"
            fitHeight
            className={classNames(
              "absolute inset-0 p-3 transition duration-100",
              {
                "opacity-0": paused,
              }
            )}
          />
        </button>
        <div className="flex flex-grow flex-col items-start justify-start gap-3">
          <div className="texture-pattern w-full bg-coral">
            <div
              className={classNames(
                "relative z-10 w-full transition duration-300",
                {
                  "opacity-0": currentTime === 0,
                }
              )}
            >
              <Player
                ref={ref}
                controls
                // controls={showControls}
                // autoplay={autoplay}
                // muted={autoplay}
                // loop={autoplay}
              >
                {provider === "youtube" && <Youtube videoId={id} />}
                {provider === "vimeo" && <Vimeo byline={false} videoId={id} />}
              </Player>
            </div>
            <div className="absolute inset-0 flex items-center justify-center text-black">
              <Icon name="logo" className="h-[20%]" fitHeight />
            </div>
          </div>
          <div className="relative h-1 w-full bg-white">
            <m.div
              className="absolute left-0 top-0 bottom-0 w-full origin-left bg-viridian"
              animate={{ scaleX: progress }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayerInline;
